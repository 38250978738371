import React from 'react';
import './navbar.css';
import { Link } from 'gatsby';
import Dropdown, { DropdownItem } from "./dropdown";
import { CgMenu, CgClose } from 'react-icons/cg'; //hamburger menu icon and X icon

//company logo
import white_logo from "../../images/excendo_white_logo.svg";
import blue_logo from "../../images/excendo_logo.svg";

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            navbar_active: false,
            mobile_menu: false,
        }

        this.mobile_menu = this.mobile_menu.bind(this);
        this.navbar_active = this.navbar_active.bind(this);
    }

    //open and close mobile menu
    mobile_menu(event) {
        event.preventDefault();
        if (this.state.mobile_menu === false) {
            this.setState({ mobile_menu: true })
        }
        else if (this.state.mobile_menu === true) {
            this.setState({ mobile_menu: false })
        }
    }

    //Change nav background if scrolled
    navbar_active() {
        if (window.scrollY >= 5) {
            this.setState({ navbar_active: true });
        } else {
            this.setState({ navbar_active: false });
        }
    }

    render() {
        //Event listener for scrolling
        if (typeof window !== `undefined`) {
            window.addEventListener('scroll', this.navbar_active)
        }

        return (
            <div style={{ height: '80px' }}>
                <nav className="navbar" id={this.state.navbar_active ? 'scroll_active' : 'scroll_disable'}>
                    <div className="container mx-auto px-4 xl:px-24">

                    {this.props.whitemode && !this.state.navbar_active && !this.state.mobile_menu?
                        <div className="items_wrapper">
                            <Link to="/">
                                <img className="h-20 py-5 xl:py-4 z-50" src={white_logo} alt="Excendo Redovisning"/>
                            </Link>

                            <div className='items'>
                                <Link to='/' className="mb-6 lg:mb-0 text-white hover:text-gray-200 flex items-center">Hem</Link>
                                <Dropdown text="Redovisning" textcolor="white">
                                    <DropdownItem href="/arsredovisning" value="Årsredovisning"/>
                                    <DropdownItem href="/bokslut" value="Bokslut" />
                                    <DropdownItem href="/bokforing" value="Bokföring" />
                                    <DropdownItem href="/deklarationer" value="Deklarationer" />
                                    <DropdownItem href="/loneadministration" value="Löneadministration" />
                                    <DropdownItem href="/fakturering" value="Fakturering" border={false} />
                                </Dropdown>
                                <Dropdown text="Affärsutveckling" textcolor="white">
                                    <DropdownItem href="/radgivning" value="Rådgivning"/>
                                    <DropdownItem href="/starta-bolag" value="Starta Bolag"/>
                                    <DropdownItem href="/controllerfunktion" value="Controllerfunktion"/>
                                    <DropdownItem href="/delgivningsmottagare" value="Delgivningsmottagare" border={false} />
                                </Dropdown>
                                <Dropdown text="Om oss" textcolor="white">
                                    <DropdownItem href="/om-oss" value="Om oss"/>
                                    <DropdownItem href="/auktoriserade-redovisningskonsult" value="Auktorisation"/>
                                    <DropdownItem href="/integritet-och-policy" value="integritet & policy" border={false}/>
                                </Dropdown>
                                <Link to='/nyheter' className="mb-6 ml-0 lg:mb-0 lg:ml-6  text-white hover:text-gray-200">Nyheter</Link>
                                <Link to='/kontakt' className="px-6 py-2 rounded-full ml-0 lg:ml-6 text-black-excendo bg-white shadow-md hover:opacity-90">Kontakt</Link>
                            </div>

                            <button className='block z-50 lg:hidden text-4xl text-white hover:text-gray-200' onClick={this.mobile_menu} onKeyDown={this.mobile_menu} >
                                {this.state.mobile_menu ? <CgClose /> : <CgMenu />}
                            </button>
                        
                        </div>
                    :
                        <div className="items_wrapper">
                            <Link to="/">
                                <img className="h-20 py-5 xl:py-4 z-50" src={blue_logo} alt="Excendo Redovisning"/>
                            </Link>


                            <div className={this.state.mobile_menu ? 'items active' : 'items'}>
                                <Link to='/' className="mb-6 lg:mb-0 text-black-excendo hover:text-blue-excendo flex items-center">Hem</Link>
                                <Dropdown text="Redovisning">
                                    <DropdownItem href="/arsredovisning" value="Årsredovisning"/>
                                    <DropdownItem href="/bokslut" value="Bokslut" />
                                    <DropdownItem href="/bokforing" value="Bokföring" />
                                    <DropdownItem href="/deklarationer" value="Deklarationer" />
                                    <DropdownItem href="/loneadministration" value="Löneadministration" />
                                    <DropdownItem href="/fakturering" value="Fakturering" border={false} />
                                </Dropdown>
                                <Dropdown text="Affärsutveckling">
                                    <DropdownItem href="/radgivning" value="Rådgivning"/>
                                    <DropdownItem href="/starta-bolag" value="Starta Bolag"/>
                                    <DropdownItem href="/controllerfunktion" value="Controllerfunktion"/>
                                    <DropdownItem href="/delgivningsmottagare" value="Delgivningsmottagare" border={false} />
                                </Dropdown>
                                <Dropdown text="Om oss">
                                    <DropdownItem href="/om-oss" value="Om oss"/>
                                    <DropdownItem href="/auktoriserade-redovisningskonsult" value="Auktorisation"/>
                                    <DropdownItem href="/integritet-och-policy" value="integritet & policy" border={false}/>
                                </Dropdown>
                                <Link to='/nyheter' className="mb-6 ml-0 lg:mb-0 lg:ml-6 text-black-excendo hover:text-blue-excendo">Nyheter</Link>
                                <Link to='/kontakt' className="px-6 py-2 rounded-full ml-0 lg:ml-6 text-white bg-blue-excendo shadow-md hover:opacity-90">Kontakt</Link>
                            </div>
                                           
                            <button className='block lg:hidden text-4xl text-black-excendo hover:text-blue-excendo z-50' onClick={this.mobile_menu} onKeyDown={this.mobile_menu} >
                                {this.state.mobile_menu ? <CgClose /> : <CgMenu />}
                            </button>
                        </div> 
                    }


                    </div>
                </nav>
            </div>
        );
    }
}
export default Navbar;

