import React from "react";
import { Link } from "gatsby";
import './footer.css';

// Footer logo
import Logo from "../../images/excendo_white_logo.svg";

// facebook logo
import FLogo from "../../images/icons/Facebook_icon.svg";

// instagram logo
import ILogo from "../../images/icons/Instagram_icon.svg";


function Footer() {

  return (
    <div className="w-screen bg-footer bg-bottom bg-no-repeat bg-cover">
      <div className="container mx-auto px-4 xl:px-24">
        <footer className="text-gray-600 body-font">
          <div className="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">

            <div className="flex-grow flex flex-wrap -mb-10 md:mt-0 mt-10 md:text-left text-center">

              {/* First column */}
              <div className="lg:w-1/4 md:w-1/2 w-full px-4">
                <h2 className="title-font font-bold text-white tracking-widest text-md mb-12 text-xl">Excendo</h2>
                <nav className="list-none mb-10 space-y-4">
                  <li>
                    <Link to='/#' className="text-white hover:text-gray-300 text-lg">Hem</Link>
                  </li>
                  <li>
                    <Link to='/om-oss' className="text-white hover:text-gray-300 text-lg">Om oss</Link>
                  </li>
                  <li>
                    <Link to='/nyheter' className="text-white hover:text-gray-300 text-lg">Nyheter</Link>
                  </li>
                  <li>
                    <Link to='/kontakt' className="text-white hover:text-gray-300 text-lg">Kontakt</Link>
                  </li>
                </nav>
              </div>
              {/* second column */}
              <div className="lg:w-1/4 md:w-1/2 w-full px-4">
                <h2 className="title-font font-bold text-white tracking-widest text-md mb-12 text-xl">Tjänster</h2>
                <nav className="list-none mb-10 space-y-4">
                  <li>
                    <Link to='/arsredovisning' className="text-white hover:text-gray-300 text-lg">Årsredovisning</Link>
                  </li>
                  <li>
                    <Link to='/bokslut' className="text-white hover:text-gray-300 text-lg">Bokslut</Link>
                  </li>
                  <li>
                    <Link to='/bokforing' className="text-white hover:text-gray-300 text-lg">Bokföring</Link>
                  </li>
                  <li>
                    <Link to='/deklarationer' className="text-white hover:text-gray-300 text-lg">Deklarationer</Link>
                  </li>
                  <li>
                    <Link to='/loneadministration' className="text-white hover:text-gray-300 text-lg">Löneadministration</Link>
                  </li>
                  <li>
                    <Link to='/fakturering' className="text-white hover:text-gray-300 text-lg">Fakturering</Link>
                  </li>
                </nav>
              </div>
              {/* Third column */}
              <div className="lg:w-1/4 md:w-1/2 w-full px-4">
                <h2 className="title-font font-bold text-white tracking-widest text-md mb-12 text-xl">Kontakt</h2>
                <nav className="list-none mb-10 space-y-4">
                  <li>
                    <p className="text-white text-lg">Gränsbovägen 4</p>
                  </li>
                  <li>
                    <p className="text-white text-lg">152 42  Södertälje</p>
                  </li>
                  <br></br>
                  <li>
                    <p className="text-white text-lg">info@excendo.se</p>
                  </li>
                  <li>
                    <p className="text-white text-lg">08 – 550 350 55</p>
                  </li>
                </nav>
              </div>
              {/* Forth column */}
              <div className="lg:w-1/4 md:w-1/2 w-full px-4">
                <nav className="list-none mb-12">
                  <li>
                    <Link to='/#' className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900" style={{ marginLeft: '15px' }}>
                      <img src={Logo} alt="Homepage logo"/>
                    </Link>
                  </li>
                  <br></br>
                  <br></br>
                  <li className="inline-flex ml-auto mr-auto">
                    <Link to="https://www.facebook.com/ExcendoRedovisning" target="_blank" className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900" style={{ marginLeft: '15px' }}>
                      <img src={FLogo} alt="facebook page"/>
                    </Link>
                    <Link to="https://www.instagram.com/excendoredovisning" target="_blank" className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900" style={{ marginLeft: '15px' }}>
                      <img src={ILogo} alt="instagram page" style={{ marginLeft: '5px' }} />
                    </Link>
                  </li>
                  <br></br>
                  <br></br>
                  <li>
                    <a target="_blank" href='/admin' className="flex title-font items-center md:justify-start justify-center text-gray-900" style={{ marginLeft: '15px' }}>
                      <button className="rounded bg-white hover:bg-gray-200 py-2 px-4 text-gray-900 text-xl">Logga In</button>
                    </a>
                  </li>
                </nav>
              </div>
            </div>
          </div>

          {/* Copyright section */}
          <div className="flex flex-wrap items-center md:justify-between justify-center pb-10">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center">
              <div className="text-sm text-white font-semibold py-1">
                © Excendo Redovisning AB - <a target="_blank" href="https://hamraz.se/">@HamrazSolution</a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}
export default Footer;